export const monthNames = [
	{ value: "January", text: "January", no: 1 },
	{ value: "February", text: "February", no: 2 },
	{ value: "March", text: "March", no: 3 },
	{ value: "April", text: "April", no: 4 },
	{ value: "May", text: "May", no: 5 },
	{ value: "June", text: "June", no: 6 },
	{ value: "July", text: "July", no: 7 },
	{ value: "August", text: "August", no: 8 },
	{ value: "September", text: "September", no: 9 },
	{ value: "October", text: "October", no: 10 },
	{ value: "November", text: "November", no: 11 },
	{ value: "December", text: "December", no: 12 },
];

export const labs = [
	{ title: "AgroLab", path: "../agrolab" },
	{ title: "HiveLab", path: "../hivelab" },
	// { title: "AIDEMEC", path: "../aidemec", coordinates: [40.575_348, 15.882_535] },
	// { title: "CONCATLL", path: "../concatll", coordinates: [41.586_168, 1.514_272] },
	{ title: "EcoReadyMasuria", path: "../ecoreadymasuria", coordinates: [53.619_53, 20.366_513] },
	{ title: "EcoVitaLL", path: "../ecovitall", coordinates: [47.492_367, 19.044_356] },
	{ title: "Esappin", path: "../esappin", coordinates: [51.443_657, 7.657_856] },
	{ title: "LivOrganic", path: "../livorganic" },
	// { title: "LOFS", path: "../lofs", coordinates: { location1: [47.710_385, 1.727_322], location2: [47.769_127, -0.327_92] } },
	{ title: "Probio", path: "../probio", coordinates: [49.039_97, 16.864_949] },
	{ title: "Seco Collab", path: "../secocollab" },
	{ title: "THALLA", path: "../thalla", coordinates: [38.533_333, 22.366_667] },
];
